import React from 'react';

import Footer from "./Footer";

const Privacy = () => (
  <>
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h1>プライバシーポリシー</h1>

          <p>仮</p>
        </div>
      </div>
    </div>

    <Footer />
  </>
);

export default Privacy;
