import React, { useState } from 'react';

import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';


function Header() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Navbar expand="md" className="navbar-dark bg-primary sticky-top">
      <NavbarBrand tag={Link} to="/">こどもの「なんで」</NavbarBrand>
      <NavbarToggler onClick={() => setIsOpen((v) => !v)} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink tag={Link} to="/about">このサイトについて</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Header;
