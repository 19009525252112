import React, { useState, useEffect, FormEvent } from 'react';

import { Link, RouteComponentProps } from "react-router-dom";
import queryString from "query-string";
import SearchIcon from '@material-ui/icons/Search';

import magic_book from "./images/magic_book.jpeg";
import { listFeaturedPosts, Post, findByQuery } from './contexts/posts';

const HomeSearchForm = ({ history }: RouteComponentProps) => {
  const [ query, setQuery ] = useState("")
  const search = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const foundByQuery = await findByQuery(query)
    if (foundByQuery && foundByQuery.slug) {
      history.push({
        pathname: `/post/${foundByQuery.slug}`
      })
      return
    }

    history.push({
      pathname: '/search',
      search: queryString.stringify({ q: query })
    })
  };
  return (
    <form className="d-flex" onSubmit={search}>
      <label className="input-group input-group-lg mr-2 mb-0">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <SearchIcon className="d-none d-md-inline-block" />
            <span className="ml-2">なんで</span>
          </span>
        </div>
        <input
          type="text"
          placeholder="夜になると暗い"
          className="form-control"
          required={true}
          value={query}
          onChange={(event) => setQuery(event.currentTarget.value)}
        />
        <div className="input-group-append">
          <span className="input-group-text">の？</span>
        </div>
      </label>
      <button className="btn btn-primary btn-lg" type="submit">
        さがす
      </button>
    </form>
  )
}

const _linkUrlForPost = (post: Post): string => {
  if (post.slug) {
    return `/post/${post.slug}`
  } else if (post.wpPostId) {
    return `/post/${post.id}`
  } else {
    return `/search?q=${encodeURIComponent(post.title)}`
  }
}

const FeaturedPostsList = () => {
  const [featuredPosts, setFeaturedPosts] = useState<Post[]>([])
  useEffect(() => {
    (async () => {
      const posts = await listFeaturedPosts()
      setFeaturedPosts(posts)
    })();
  }, []);

  return (
    <ul className="list-unstyled">
      {featuredPosts.map((post) => (
        <li className="mr-2 mb-1 d-inline-block" key={post.id}>
          <Link to={_linkUrlForPost(post)} className="btn btn-sm btn-light">
            なんで{post.title}の
          </Link>
        </li>
      ))}
    </ul>
  )
}

const Home = ({ history, location, match }: RouteComponentProps) => (
  <section className="bg-gradient-top index-top-page-search">
    <img src={magic_book} alt="bg" className="bg-image opacity-20" />
    <div className="container">
      <div className="row justify-content-center text-center index-top-page-search-row">
        <div className="col-xl-9">
          <h1 className="display-4 text-white">こどもの「なんで」</h1>
          <p className="lead text-white">「なんで」と言い続けるこどもと親のための検索エンジン</p>
          <HomeSearchForm {...{ history, location, match }} />
          <div className="mt-2 d-flex justify-content-center">
            <FeaturedPostsList />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Home;
