import React, { useEffect } from 'react';

import queryString from "query-string";
import { RouteComponentProps } from "react-router-dom";

import Footer from "./Footer";
import SearchHeader from './components/SearchHeader';

interface SearchResultsProps {
  query: string
}

const SearchResults = ({query}: SearchResultsProps) => {
  useEffect(() => {
    (function() {
      var cx = '007521930919518128877:cxezii_pv5w';
      var gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      var s = document.getElementsByTagName('script')[0];
      if (s && s.parentNode) {
        s.parentNode.insertBefore(gcse, s);
      }
    })();
  }, []);

  return (
    <section className="pt-0 mt-minus-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-8 col-xl-7">
            <article
              className="search-article"
              data-query={query}
              dangerouslySetInnerHTML={{ __html: `<gcse:searchresults-only></gcse:searchresults-only>`}}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const Search = ({ location, history }: RouteComponentProps) => {
  const parsedQuery = queryString.parse(location.search)
  const queryFromQSA = parsedQuery.q || ""
  let query: string
  if (Array.isArray(queryFromQSA)) {
    query = queryFromQSA[0]
  } else {
    query = queryFromQSA
  }

  return (
    <>
      <SearchHeader
        query={query}
        history={history}
      />
      <SearchResults query={query} />

      <Footer />
    </>
  );
};

export default Search;
