import axios from "axios"

const SITE_ID = process.env.REACT_APP_WPCOM_SITE_ID

export interface WpcomPost {
  content: {
    rendered: string
  }

  excerpt: {
    rendered: string
  }

  _embedded: {
    "wp:featuredmedia": {
      source_url: string
      alt_text?: string
    }[]
  }
}

export async function getPost(id: number): Promise<WpcomPost | undefined> {
  const resp = await axios.get<WpcomPost>(
    `https://public-api.wordpress.com/wp/v2/sites/${SITE_ID}/posts/${id}?_embed`
  )
  const post = resp.data
  return post
}
