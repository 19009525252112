import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { History } from 'history';
import queryString from 'query-string';
import { findByQuery } from '../contexts/posts';

interface SearchFormProps {
  initialQuery: string
  onSearch: (query: string) => void
}

const SearchForm = ({ initialQuery, onSearch }: SearchFormProps) => {
  const [inflightQuery, setInflightQuery] = useState(initialQuery);
  const [dummyElement, setDummyElement] = useState<HTMLSpanElement | null>(null);
  const [dynamicInputWidth, setDynamicInputWidth] = useState<number>(100);
  const searchInputEl = useRef<HTMLInputElement>(null);

  const onNextQuestionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setInflightQuery("");

    if (searchInputEl && searchInputEl.current) {
      searchInputEl.current.focus();
    }
  }

  useEffect(() => {
    let elem = dummyElement;
    if (!elem) {
      elem = document.createElement("span");
      elem.setAttribute('style', 'position: absolute; opacity: 0; pointer-events: none;')
      document.body.appendChild(elem);
      setDummyElement(elem);
    }

    if (searchInputEl && searchInputEl.current) {
      const computedStyle = window.getComputedStyle(searchInputEl.current);
      elem.setAttribute('style', `position: absolute; opacity: 0; pointer-events: none; font: ${computedStyle.font}`);
    }

    if (inflightQuery.length > 0) {
      elem.textContent = inflightQuery;
    } else {
      elem.textContent = initialQuery;
    }
    const width = elem.clientWidth;
    setDynamicInputWidth(width + 16);
  }, [dummyElement, inflightQuery, searchInputEl, initialQuery]);

  return (
    <>
      <form onSubmit={(event) => { event.preventDefault(); onSearch(inflightQuery) }}>
        <h1 className="page-main-title">
          <span className="mx-1">なんで</span>
          <input
            ref={searchInputEl}
            type="text"
            className="re-search-input width-dynamic mx-1"
            value={inflightQuery}
            placeholder={initialQuery}
            onChange={(event) => setInflightQuery(event.currentTarget.value)}
            style={{
              width: dynamicInputWidth
            }}
            name="q" />
          <span className="mx-1">の？</span>
        </h1>
      </form>
      <div className="mt-3">
        <button
          className="btn btn-sm btn-primary"
          onClick={onNextQuestionClick}
        >次を聞く</button>
      </div>
    </>
  )
};

export interface SearchHeaderProps {
  query: string
  history: History<any>
  leadText?: string
}

const SearchHeader = ({query, history, leadText}: SearchHeaderProps) => {
  const [internalQuery, setInternalQuery] = useState<string>(query)

  const handleSearchQuery = async (query: string) => {
    const foundByQuery = await findByQuery(query)
    if (foundByQuery && foundByQuery.slug) {
      history.push({
        pathname: `/post/${foundByQuery.slug}`
      })
      return
    }

    history.push({
      pathname: '/search',
      search: queryString.stringify({ q: query })
    })
    if (typeof window.google !== "undefined") {
      window.google.search.cse.element.getElement('searchresults-only0').execute(query)
    }
  }

  return (
    <section className="bg-white pt-3">
      <div className="container">
        <div className="row mb-5 justify-content-between align-items-center">
          <div className="col-auto">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb p-0 bg-transparent mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">トップ</Link>
                </li>
                <li className="breadcrumb-item">
                  なんで「{internalQuery}」の？
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row justify-content-center text-center mb-3">
          <div className="col-md-11 col-lg-10 col-xl-9">
            <div className="my-3">
              <SearchForm
                initialQuery={internalQuery}
                onSearch={(query) => {
                  handleSearchQuery(query)
                  setInternalQuery(internalQuery)
                }}
              />
            </div>
            {leadText && <div className="lead" dangerouslySetInnerHTML={{__html: leadText}} />}
          </div>
        </div>
      </div>
    </section>
  )
};

export default SearchHeader;
