import React from 'react';

import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import Header from './Header';
import Home from "./Home";
import About from "./About";
import Privacy from "./Privacy";
import Search from "./Search";
import PostDetail from "./PostDetail";

const history = createBrowserHistory();

history.listen((location, action) => {
  if (window.gtag) {
    window.gtag('config', process.env.REACT_APP_GA_ACCOUNT_ID, {
      page_path: location.pathname + location.search
    })
  }
});

function App() {
  return (
    <Router history={history}>
      <Header />

      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/search" component={Search} />
      <Route exact path="/post/:slug" component={PostDetail} />
    </Router>
  );
}

export default App;
