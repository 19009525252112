import React from 'react';

import Footer from "./Footer";

import header_8 from "./images/theme/header-8.jpg";
import profile_keita from "./images/theme/profile-keita.jpg";
import profile_naoko from "./images/theme/profile-naoko.jpg";

const About = () => (
  <>
    <section className="bg-dark">
      <img src={header_8} alt="Background" className="bg-image opacity-70" />
      <div className="container height-lg-30">
        <div className="row">
          <div className="col-md-8 col-lg-7 col-xl-6">
            <h1 className="display-4 text-white">
              このサイトについて
            </h1>
            <p className="lead mb-0 text-white">
              「なんでこのサイトを作ったのですか？」
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <article>
              <h2>子供の「なんで？」に日々答えるために</h2>
              <p>
                娘が4歳を過ぎた頃から、一日に何度も「なんで？」と質問するようになりました。
                親として、知っていることを答えたり、一緒に考えたりするのは楽しくもありますが、時に大変でもあります。
              </p>
              <p>
                それでも娘にはたくさんの「なんで？」を通して世界を理解していってほしいという気持ちから、このサイトを立ち上げました。
                同じように子供と一緒に身の回りの疑問を考え、楽しんでいこうとする保護者の方にも活用してもらえれば幸いです。
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <h3>
              サイト運営者の紹介
            </h3>
          </div>
        </div>
        <div className="row mb-4 justify-content-center">
          <div className="col-6 col-md-4 col-xl-3 d-flex">
            <div className="card">
              <img className="card-img-top" src={profile_keita} alt="小林 敬太郎" />
              <div className="card-body py-3">
                <h5 className="mb-0">小林 敬太郎</h5>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-xl-3 d-flex">
            <div className="card">
              <img className="card-img-top" src={profile_naoko} alt="高野 直子" />
              <div className="card-body py-3">
                <h5 className="mb-0">高野 直子</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-sm-auto d-md-flex align-items-center text-center">
            <span className="d-block mr-md-3 mb-md-0 h4 mb-md-0">一緒にこのサイトを作っていきませんか？</span>
            <a className="btn btn-primary btn-lg" href={`mailto:keita@kotobamedia.com?subject=${encodeURIComponent("こどもの「なんで」の採用について")}`}>採用のお問い合わせ</a>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </>
);

export default About;
