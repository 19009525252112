export interface Post {
  id: number
  slug?: string
  title: string
  wpPostId?: number
  featured: boolean
  aliases?: string[]
}

const POSTS: Post[] = [
  // {
  //   id: 1,
  //   title: "夜は暗い",
  //   slug: "dark-night"
  // },
  {
    id: 3,
    title: "金属はさびる",
    featured: true,
  },
  {
    id: 4,
    title: "空は青い",
    featured: true
  },
  {
    id: 5,
    title: "息をする",
    featured: true,
  },
  {
    id: 6,
    title: "色を混ぜると違う色になる",
    slug: "mixing-colors",
    wpPostId: 18,
    featured: true,
    aliases: [
      "色を混ぜる",
      "色をまぜる",
      "いろを混ぜる",
      "いろをまぜる"
    ]
  }
]

export async function listFeaturedPosts() {
  return POSTS.filter((post) => post.featured);
}

export async function findPostBySlugOrId(id: string | number): Promise<Post | undefined> {
  const findBySlug = POSTS.find((post) => post.slug === id);
  if (findBySlug) {
    return findBySlug;
  }

  const findById = POSTS.find((post) => post.id === id)
  return findById;
}

export async function findByQuery(query: string): Promise<Post | undefined> {
  const byQuery = POSTS.find((post) => post.title === query);
  return byQuery;
}
