import React, { useEffect, useState } from "react";

import Footer from "./Footer";
import { RouteComponentProps } from "react-router";
import { Post, findPostBySlugOrId } from "./contexts/posts";
import SearchHeader from "./components/SearchHeader";
import { History } from "history";
import { getPost, WpcomPost } from "./contexts/wpcom";

interface PostContentsProps {
  post: WpcomPost
}

const PostContents = ({post}: PostContentsProps) => {
  let embeddedImage
  if (post._embedded["wp:featuredmedia"].length >= 0) {
    embeddedImage = post._embedded["wp:featuredmedia"][0]
  }
  return (
    <section className="pt-0 mt-minus-3">
      <div className="container">
        <div className="row justify-content-center mb-4 mb-md-5">
          <div className="col-xl-10">
            {embeddedImage && <img src={embeddedImage.source_url} alt={embeddedImage.alt_text || "イメージ画像"} className="rounded shadow-lg" />}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-8 col-xl-7">
            <article dangerouslySetInnerHTML={{__html: post.content.rendered}} />
          </div>
        </div>
      </div>
    </section>
  )
}

interface PostDetailInnerProps {
  post?: Post
  history: History<any>
}

const PostDetailInner = ({post, history}: PostDetailInnerProps) => {
  const [wpcomPost, setWpcomPost] = useState<WpcomPost | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (!post || !post.wpPostId) { return; }
      const wpcp = await getPost(post.wpPostId);
      setWpcomPost(wpcp);
    })();
  }, [post]);

  if (!post) { return null; }

  return <>
    <SearchHeader
      query={post.title}
      history={history}
      leadText={wpcomPost && wpcomPost.excerpt.rendered}
    />

    {wpcomPost && <PostContents post={wpcomPost} />}
  </>
}

interface PostDetailRouteParams {
  slug?: string
}

const PostDetail = ({ match: { params }, history }: RouteComponentProps<PostDetailRouteParams>) => {
  const [post, setPost] = useState<Post | undefined>(undefined)

  useEffect(() => {
    (async () => {
      const slug = params.slug
      if (!slug) { return }
      const post = await findPostBySlugOrId(slug)
      if (!post) { return }

      setPost(post)
    })();
  }, [params.slug]);

  return (
    <>
      <PostDetailInner post={post} history={history} />

      <Footer />
    </>
  );
};

export default PostDetail;
