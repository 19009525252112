import React from 'react';

import { Link } from "react-router-dom";
import moment from 'moment';

const Footer = () => (
  <footer className="spacer-y-3 bg-dark">
    <div className="container">
      <div className="row mt-3 mt-md-4">
        <div className="col">
          <span className="text-small text-white opacity-50">© {moment().year()} <a className="text-white" href="https://kotobamedia.com">KotobaMedia</a></span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <ul className="list-unstyled d-flex list-pipe-separator">
            <li>
              <Link to="/about" className="text-white text-small">このサイトについて</Link>
            </li>
            <li>
              <Link to="/privacy" className="text-white text-small">プライバシーポリシー</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
